import React from 'react';

const Multimedia = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Multimedia</h3>
            <div className="skills__box">
                <div className="skills__group">
                    <div className="skills__data">
                        <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="20" height="20"><path d="M19,4h-.508L16.308,1.168A3.023,3.023,0,0,0,13.932,0H10.068A3.023,3.023,0,0,0,7.692,1.168L5.508,4H5A5.006,5.006,0,0,0,0,9V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V9A5.006,5.006,0,0,0,19,4ZM9.276,2.39A1.006,1.006,0,0,1,10.068,2h3.864a1.008,1.008,0,0,1,.792.39L15.966,4H8.034ZM22,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V9A3,3,0,0,1,5,6H19a3,3,0,0,1,3,3Z" /><path d="M12,8a6,6,0,1,0,6,6A6.006,6.006,0,0,0,12,8Zm0,10a4,4,0,1,1,4-4A4,4,0,0,1,12,18Z" /></svg>
                        <div>
                            <h3 className="skills__name">Fotografía</h3>
                            <span className="skills__level">Fotográfo de Eventos</span>
                            <span className="skills__level">Fotográfo de Modelos</span>
                            <span className="skills__level">Productshot</span>
                            <span className="skills__level">Edición Digital</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="22" height="22"><path d="m22.575,10.152c-.866-.358-1.854-.163-2.517.5l-.438.438c-.433-1.044-1.21-1.91-2.189-2.458.965-.912,1.568-2.203,1.568-3.633,0-2.757-2.243-5-5-5-1.634,0-3.087.788-4,2.003-.913-1.216-2.366-2.003-4-2.003C3.243,0,1,2.243,1,5c0,1.429.603,2.721,1.568,3.633-1.531.856-2.568,2.493-2.568,4.367v6c0,2.757,2.243,5,5,5h10c2.081,0,3.869-1.278,4.621-3.09l.438.438c.443.443,1.031.678,1.63.678.298,0,.599-.058.886-.177.866-.358,1.425-1.196,1.425-2.133v-7.429c0-.938-.56-1.775-1.425-2.133ZM14,2c1.654,0,3,1.346,3,3s-1.346,3-3,3-3-1.346-3-3,1.346-3,3-3Zm-8,0c1.654,0,3,1.346,3,3s-1.346,3-3,3-3-1.346-3-3,1.346-3,3-3Zm12,17c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3v-6c0-1.654,1.346-3,3-3h10c1.654,0,3,1.346,3,3v6Zm4,.714c0,.171-.104.25-.191.286-.086.036-.215.054-.337-.067l-1.472-1.472v-4.922l1.472-1.472c.122-.121.251-.103.337-.067.087.036.191.115.191.286v7.429Z" /></svg>
                        <div>
                            <h3 className="skills__name">Producción de Video</h3>
                            <span className="skills__level">Manejo de Cámara</span>
                            <span className="skills__level">Piloto de Drone</span>
                            <span className="skills__level">Grid/Runner</span>
                        </div>
                    </div>

                    <div className="skills__data">
                    <svg id="Layer_1" height="22" viewBox="0 0 24 24" width="22" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m19 14a4.929 4.929 0 0 0 -2.352.615l-3.348-4.015 7.467-8.96a1 1 0 0 0 -1.536-1.28l-7.231 8.678-7.232-8.678a1 1 0 0 0 -1.536 1.28l7.468 8.96-3.348 4.015a4.941 4.941 0 0 0 -2.352-.615 5 5 0 1 0 5 5 4.947 4.947 0 0 0 -1.112-3.1l3.112-3.738 3.111 3.738a4.955 4.955 0 0 0 -1.111 3.1 5 5 0 1 0 5-5zm-14 8a3 3 0 1 1 3-3 3 3 0 0 1 -3 3zm14 0a3 3 0 1 1 3-3 3 3 0 0 1 -3 3z"/></svg>
                        <div>
                            <h3 className="skills__name">Post Producción</h3>
                            <span className="skills__level">Edición de Video</span>
                            <span className="skills__level">Edición de Audio</span>
                            <span className="skills__level">Corrección de Color</span>
                        </div>
                    </div>
                </div>
                <div className="skills__group">
                    <div className="skills__data">
                    <svg id="Layer_1" height="22" viewBox="0 0 24 24" width="22" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m18 9.064a3.049 3.049 0 0 0 -.9-2.164 3.139 3.139 0 0 0 -4.334 0l-11.866 11.869a3.064 3.064 0 0 0 4.33 4.331l11.87-11.869a3.047 3.047 0 0 0 .9-2.167zm-14.184 12.624a1.087 1.087 0 0 1 -1.5 0 1.062 1.062 0 0 1 0-1.5l7.769-7.77 1.505 1.505zm11.872-11.872-2.688 2.689-1.5-1.505 2.689-2.688a1.063 1.063 0 1 1 1.5 1.5zm-10.825-6.961 1.55-.442.442-1.55a1.191 1.191 0 0 1 2.29 0l.442 1.55 1.55.442a1.191 1.191 0 0 1 0 2.29l-1.55.442-.442 1.55a1.191 1.191 0 0 1 -2.29 0l-.442-1.55-1.55-.442a1.191 1.191 0 0 1 0-2.29zm18.274 14.29-1.55.442-.442 1.55a1.191 1.191 0 0 1 -2.29 0l-.442-1.55-1.55-.442a1.191 1.191 0 0 1 0-2.29l1.55-.442.442-1.55a1.191 1.191 0 0 1 2.29 0l.442 1.55 1.55.442a1.191 1.191 0 0 1 0 2.29zm-5.382-14.645 1.356-.387.389-1.358a1.042 1.042 0 0 1 2 0l.387 1.356 1.356.387a1.042 1.042 0 0 1 0 2l-1.356.387-.387 1.359a1.042 1.042 0 0 1 -2 0l-.387-1.355-1.358-.389a1.042 1.042 0 0 1 0-2z"/></svg>
                        <div>
                            <h3 className="skills__name">Diseño</h3>
                            <span className="skills__level">Diseño Gráfico</span>
                            <span className="skills__level">Diseño Web</span>

                        </div>
                    </div>

                    <div className="skills__data">
                    <svg id="Layer_1" height="22" viewBox="0 0 24 24" width="22" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><polygon points="15.129,1.38 24,1.38 24,22.62  "/><polygon points="8.878,1.38 0,1.38 0,22.62  "/><polygon points="12.004,9.208 17.656,22.62 13.951,22.62 12.261,18.349 8.125,18.349  "/></svg>
                        <div>
                            <h3 className="skills__name">Adobe</h3>
                            <span className="skills__level">After Effects</span>
                            <span className="skills__level">Audition</span>
                            <span className="skills__level">Adobe XD</span>
                            <span className="skills__level">Figma</span>
                            <span className="skills__level">Illustrator</span>
                            <span className="skills__level">Lightroom</span>
                            <span className="skills__level">Premiere</span>
                            <span className="skills__level">Photoshop</span>
                        </div>
                    </div>


                </div>



            </div>
        </div>
    )
}

export default Multimedia