import React from 'react';
import './skills.css';
import Multimedia from './Multimedia';
import Designer from  './Designer';

const Skills = () => {
  return (
    <section className="skills section" id="skills">
        <h2 className="section__title">Habilidades</h2>
        <span className="section__subtitle">Lo que sé</span>

        <div className="skills__container container grid">
        <Multimedia/>
        <Designer/>
      
        </div>

    </section>

    )
}

export default Skills