import React, { useState } from 'react';
import Modal from './Modal';

const WorkItems = ({ item }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <div>
      {modalOpen && <Modal image={item.image} toggleModal={toggleModal} title={item.title}  description={item.description}/>}
      <div className='work__card' >
        <h3 className='work__title'>{item.title}</h3>
        <img src={item.image} alt={item.description} title={item.description} className='work__img' width="1200" height="600" />
        <br/>
        <br/>
        <span onClick={toggleModal} className="work__button">Ver
        <svg className="work__button-icon" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="12" height="12"><path d="M23.271,9.419C21.72,6.893,18.192,2.655,12,2.655S2.28,6.893.729,9.419a4.908,4.908,0,0,0,0,5.162C2.28,17.107,5.808,21.345,12,21.345s9.72-4.238,11.271-6.764A4.908,4.908,0,0,0,23.271,9.419Zm-1.705,4.115C20.234,15.7,17.219,19.345,12,19.345S3.766,15.7,2.434,13.534a2.918,2.918,0,0,1,0-3.068C3.766,8.3,6.781,4.655,12,4.655s8.234,3.641,9.566,5.811A2.918,2.918,0,0,1,21.566,13.534Z"/><path d="M12,7a5,5,0,1,0,5,5A5.006,5.006,0,0,0,12,7Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,12,15Z"/></svg>
        </span>
      </div>
    </div>
  );
};

export default WorkItems;
