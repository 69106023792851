import Work1 from '../../assets/diseno-de-web-ui-ux-para-marsilvestre.webp';
import Work2 from '../../assets/diseno-de-web-ui-ux-para-casaoaxaca.webp';
import Work3 from '../../assets/fotografia-para-galeria-new-animal.webp';
import Work4 from '../../assets/produccion-de- video-Restaurante-Anju.webp';
import Work5 from '../../assets/diseno-grafico-de-playeras-para-equipo-de-softball.webp';
import Work6 from '../../assets/diseno-de-brochure-para-el-estero-el-salado.webp';
import Work7 from '../../assets/propuesta-de-diseno-brochure-para-el-estero-el-salado.webp';
import Work8 from '../../assets/productshot-de-cojines-para-artesanias-fotografia-en-vallarta.webp';
import Work9 from '../../assets/productshot-de-cojines-para-artesanias-toma-fotos-en-bahia-de-banderas.webp';
import Work10 from '../../assets/productshot-de-cojines-para-artesanias-toma-fotos-en-vallarta.webp';
import Work11 from '../../assets/cinematografia-curso-de-manejo-de-sony-fs5.webp';
import Work12 from '../../assets/produccion-de-video-institucional.webp';
import Work13 from '../../assets/produccion-de video-para-hotel Sunset-Island-resort.webp';
import Work14 from '../../assets/produccion-de- video-para-el-estado-de-Nayarit.webp';
import Work15 from '../../assets/fotografia-digital empresa-de-cosmeticos.webp';
import Work16 from '../../assets/diseno-ux-ui-de app-Leongs-Nursery.webp';
import Work17 from '../../assets/edicion-de-fotografia-montaje-digital empresa-de-cosmeticos.webp';
import Work18 from '../../assets/montaje-digital-empresa-de-cosmeticos.webp';
import Work19 from '../../assets/diseno-3D-para-latinoamerica-systems-summit 2023.webp';
import Work20 from '../../assets/diseno-3D-para-Del-Paso-Films.webp';


export const projectsData = [
    {
      id: 1,
      image: Work1,
      title: 'Diseño Web',
      category: 'diseño',
      description:'Diseño web para MarSilvestre',
    },
    {
      id: 2,
      image: Work2,
      title: 'Diseño web',
      category: 'diseño',
      description:'Diseño y desarollo para Tienda Artesanias Oaxaca ',
    },
    {
      id: 3,
      image: Work3,
      title: 'Fotografía',
      category: 'foto',
      description:'Fotografía para exposición en Galeria New Animal',
    },
    {
      id: 4,
      image: Work4,
      title: 'Video',
      category: 'video',
      description:'Producción de video Restaurante Anju',
    },
    {
      id: 5,
      image: Work5,
      title: 'Diseño Gráfico',
      category: 'Todo',
      description:'Diseño para equipo de Softball',
    },
    {
      id: 6,
      image: Work6,
      title: 'Diseño Gráfico',
      category: 'Diseño',
      description:'Brochure para Estero el Salado',
    },
    {
      id: 7,
      image: Work7,
      title: 'Diseño Gráfico',
      category: 'Diseño',
      description:'Brochure para Estero el Salado',
    },
    {
      id: 8,
      image: Work8,
      title: 'Fotografía',
      category: 'foto',
      description:'Productshot de artesanias Oaxaca',
    },
    {
      id: 9,
      image: Work9,
      title: 'Fotografía',
      category: 'foto',
      description:'Productshot de artesanias Oaxaca',
    },
    {
      id: 10,
      image: Work10,
      title: 'Fotografía',
      category: 'foto',
      description:'Productshot de artesanias Oaxaca',
    },
    {
      id: 11,
      image: Work11,
      title: 'Video',
      category: 'video',
      description:'Curso de manejo de Sony FS5',
    },
    {
      id: 12,
      image: Work12,
      title: 'Video',
      category: 'video',
      description:'Producción de video institucional',
    },
    {
      id: 13,
      image: Work13,
      title: 'Video',
      category: 'video',
      description:'Producción de video para hotel Sunset Island Resort',
    },
    {
      id: 14,
      image: Work14,
      title: 'Video',
      category: 'video',
      description:'Producción de video para el estado de Nayarit',
    },
    {
      id: 15,
      image: Work15,
      title: 'Fotografía',
      category: 'foto',
      description:'Montaje digital empresa de cosméticos',
    },
    {
      id: 16,
      image: Work16,
      title: 'Diseño UI',
      category: 'diseño',
      description:'Diseño de app Leongs Nursery',
    },
    {
      id: 17,
      image: Work17,
      title: 'Fotografía',
      category: 'foto',
      description:'Montaje digital empresa de cosméticos',
    },
    {
      id: 18,
      image: Work18,
      title: 'Fotografía',
      category: 'foto',
      description:'Montaje digital empresa de cosméticos',
    },
    {
      id: 19,
      image: Work19,
      title: 'Diseño 3D',
      category: 'diseño',
      description:'Diseño 3D para Latinoamérica Systems Summit 2023',
    },
    {
      id: 20,
      image: Work20,
      title: 'Diseño 3D',
      category: 'diseño',
      description:'Diseño 3D para Del Paso Films',
    },

  ];
  
  export const projectsNav = [
    {
        name: 'todo',
    },
    {
        name: 'video',
    },
    {
        name: 'foto',
    },
    {
        name: 'diseño',
    },
  ];
  
  
  