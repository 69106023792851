import React from 'react';
import "./App.css";
import Header from './components/header/header.jsx';
import Home from './components/home/home.jsx';
import About from './components/about/About.jsx'
import Skills from './components/skills/Skills';
import Works from './components/Works/Works';
import Services from './components/services/Services.jsx';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import Scrollup from './components/scrollup/Scrollup';

const App = () => {

  return (

    <>
      <Header />
      <main className='main'>
        <Home />
        <About />
        <Skills />
        <Services />
        <Works />
        <Contact />
      </main>
      <Footer />
      <Scrollup />
    </>
  );
};
export default App