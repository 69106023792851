import React from 'react';
import './modal.css'; 

const Modal = ({ image, description, title, toggleModal }) => {
  console.log('description:', description);
  console.log('title:', title);

    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('work__modal')) {
          toggleModal();
        }
      };
  return (
    <div className="work__modal active-modal" onClick={handleOutsideClick}>
      <div className="work__modal-content">
        <h3 className='work__title'>{title}</h3>
        <img src={image} alt={description} className="modal-image" />
        <p className='work__modal-description'>{description}</p>
        <svg  onClick={toggleModal}  className="work__modal-close" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="20" height="20"><path d="M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z"/></svg>
      </div>
    </div>
  );
};

export default Modal;
