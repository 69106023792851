import React from 'react';


const Data = () => {
  return (
   <div className="home__data"> 
<h3 className='home__title'>Angel Ayodoro</h3>
<p className="home__description">Ing. en Comunicación Multimedia</p>
<a href="#contact" className="button-home button--flex">Hablemos</a>

</div>
  )
}

export default Data