import React from 'react';
import "./about.css";
import AboutImg from "../../assets/angel-ayodoro-foto-de-perfil-acerca -de- mi.webp";
import Info from './Info.jsx';

const about = () => {
  return (
    <section className="about" id="about">
      <h2 className="section__title">Acerca de mí</h2>
      <span className="section__subtitle">Te cuento</span>
      <div className="about__container container grid">
        <img src={AboutImg} alt="" className="about__img" />
        <div className="about__data">
          <Info />

          <p className="about__description">
            Egresado de la Universidad de Guadalajara.<br />
            5+ años de experiencia y apasionado en producción de video, fotografía y diseño gráfico.
            Gusto por el desarrollo Web: HTML5, CSS3, Javascript.

          </p>
          <a href="#contact" className="button-about button--flex">Descarga CV</a>


        </div>

      </div>
    </section>
  )
}

export default about