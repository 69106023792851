import React from 'react'

const Designer = () => {
  return (
    <div className="skills__content">
    <h3 className="skills__title">Social Media</h3>
    <div className="skills__box">
        <div className="skills__group">
            <div className="skills__data">
            <svg  id="Layer_1" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="M12.479,14.265v-3.279h11.049c0.108,0.571,0.164,1.247,0.164,1.979c0,2.46-0.672,5.502-2.84,7.669   C18.744,22.829,16.051,24,12.483,24C5.869,24,0.308,18.613,0.308,12S5.869,0,12.483,0c3.659,0,6.265,1.436,8.223,3.307L18.392,5.62   c-1.404-1.317-3.307-2.341-5.913-2.341C7.65,3.279,3.873,7.171,3.873,12s3.777,8.721,8.606,8.721c3.132,0,4.916-1.258,6.059-2.401   c0.927-0.927,1.537-2.251,1.777-4.059L12.479,14.265z"/></svg>
                <div>
                    <h3 className="skills__name">Google</h3>
                    <span className="skills__level">SEO</span>
                    <span className="skills__level">Analytics</span>
                    <span className="skills__level">Tag Manager</span>
                    <span className="skills__level">Ads</span>
                    <span className="skills__level">Youtube</span>
               
                </div>
            </div>

            <div className="skills__data">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="22" height="22"><path d="M8.389,21.682L.915,14.207c-1.17-1.17-1.17-3.073,0-4.243L8.439,2.439l2.121,2.121L3.036,12.085l7.475,7.475-2.121,2.121Zm14.732-7.475c1.17-1.17,1.17-3.073,0-4.243L15.596,2.439l-2.121,2.121,7.525,7.525-7.475,7.475,2.121,2.121,7.475-7.475Z"/></svg>
                <div>
                <h3 className="skills__name">Desarrollo Web</h3>
                    <span className="skills__level">Html</span>
                    <span className="skills__level">CSS</span>
                    <span className="skills__level">JavaScript</span>
                    <span className="skills__level">Elementor</span>
                    <span className="skills__level">Wordpress</span>
                    
                </div>
            </div>

            <div className="skills__data">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><g id="_01_align_center" data-name="01 align center"><path d="M19.333,14.667a4.66,4.66,0,0,0-3.839,2.024L8.985,13.752a4.574,4.574,0,0,0,.005-3.488l6.5-2.954a4.66,4.66,0,1,0-.827-2.643,4.633,4.633,0,0,0,.08.786L7.833,8.593a4.668,4.668,0,1,0-.015,6.827l6.928,3.128a4.736,4.736,0,0,0-.079.785,4.667,4.667,0,1,0,4.666-4.666ZM19.333,2a2.667,2.667,0,1,1-2.666,2.667A2.669,2.669,0,0,1,19.333,2ZM4.667,14.667A2.667,2.667,0,1,1,7.333,12,2.67,2.67,0,0,1,4.667,14.667ZM19.333,22A2.667,2.667,0,1,1,22,19.333,2.669,2.669,0,0,1,19.333,22Z"/></g></svg>
                <div>
                <h3 className="skills__name">Redes Sociales</h3>
                    <span className="skills__level">Meta Business</span>
                    <span className="skills__level">Meta Ads</span>
    
                </div>
            </div>
        </div>
        
    </div>
</div>
  )
}

export default Designer