import React from 'react';
import './Works.css';
import Work from './Work';

const Works = () => {
  return (
<div className="work section" id="portfolio">
    <h2 className="section__title">Portafolio</h2>
    <span className="section__subtitle">Más recientes</span>

    <Work />
</div>
 )
}

export default Works